import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Icon from 'components/icon'
import logo from '../../content/images/logo.svg'

const desc = {
  description:
    'eイーセイリは最安値の優良業者を簡単に見つけ出すサービスです。遺品整理を止むを得ず依頼する人々に対して、頼りにされ、必要とされるサービスとなるために行動しています。',
  siteUrl: 'https://ei-seiri.com',
  author: 'eイーセイリ',
}
class About extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={desc} title="eイーセイリとは" />
        <header className="jumbotron rounded-0">
          <div className="container mw7">
            <h1 className="d-flex align-items-top">
              <img
                src={logo}
                className="img-fliud mb-0 mr-1"
                height="34px"
                width="160px"
                alt="eイーセイリ"
              />
              <span>とは</span>
            </h1>
            <p className="mb-0 text-muted font-weight-bold">
              eイーセイリは最安値の優良業者を簡単に見つけ出すサービスです。
            </p>
          </div>
        </header>

        <section>
          <div className="container mw7 py-3">
            <h2>
              <ruby>
                <rb>eイーセイリ</rb>
                <rp>（</rp>
                <rt>いいーせいり</rt>
                <rp>）</rp>
              </ruby>
              とは
            </h2>
            <p className="text-muted text-justify font-weight-bold">
              遺品整理を止むを得ず依頼する人々に対して、頼りにされ、必要とされるサービスとなるために行動してまいります。
            </p>
            <p className="text-justify">
              eイーセイリは、設立以来、遺品整理に求められる価格と品質を追求し、遺品整理や相続といった空き家について、高い信頼性と高い安全性の観点を持って、遺品整理サービスを提供する事業者の規範となるような組織になることを目指しています。
            </p>
            <p className="text-justify">
              加えて、行政や他の団体、いわゆる8士業といった専門家との分け隔てのない交流を行うことで、関係法令の熟知と、サービス品質向上のために活動しています。
            </p>
            <p className="text-justify">
              eイーセイリの中核サービスとなる「遺品整理一括見積もり比較サイト」を中心とし、遺品整理企業単独では困難な企業間でのサービス品質差の是正を目的としています。
            </p>
            <p className="text-justify">
              一般にマッチングサービスと呼ばれる手法をとるインターネットの見積もりサービスでは、その収益体質が広告費や紹介手数料であることが多く、業者間の広告出稿金額の多少や、広告出稿のペースにより、どうしても資本力のある企業に目が向きがちです。
            </p>
            <p className="text-justify">
              eイーセイリは、一般的な不動産仲介業などのような中間マージンを一切要求いたしません。このため、消費者の方への手数料等の請求を一切いたしません。
            </p>
          </div>
        </section>

        <section>
          <div className="container mw7 py-3">
            <h2>eイーセイリの事業内容</h2>
            <p className="text-muted text-justify font-weight-bold">
              優良企業を消費者が負担なく選ぶことができる一括見積もりサービス
            </p>
            <p className="text-justify">
              eイーセイリは、遺品整理を主とする専門事業者との交流の元に構成される民間のサービス団体です。特定の遺品整理業者の利益とならないとよう、公正・中立な消費者の視点でサービスを運営しています。
            </p>
            <p className="text-justify">
              世の中の役に立つために、優良な遺品整理企業の仲間と共にご依頼主であるお客様の利益を第一に考えた事業を展開してまいります。
            </p>
            <div className="alert alert-primary rounded-0 shadow-sm">
              <h3 className="font-weight-bold">主な事業</h3>
              <ul className="h5 mb-0">
                <li>優良企業のみ参加の遺品整理一括見積もりサービス</li>
                <li>インターネットや会報誌などの広報活動</li>
                <li>関係専門家との連携・交流事業</li>
              </ul>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default About
